import React from 'react';
import axios from 'axios';
import { PrimaryButton } from 'components/UI/Button';

function AbihPayPage() {
  const createOrder = async () => {
    const response = await axios('https://api.abhipay.com.pk/api/v2/createOrder', {
      method: 'POST',
      data: {
        merchant: 'ES1091013',
        body: {
          amount: 10,
          language: 'EN',
          currencyType: 'PKR',
          description: 'This is Description',
          approveURL: 'http://localhost:8000/google',
          cancelURL: 'http://localhost:8000/cancel',
          declineURL: 'http://localhost:8000/decline',
          uuid: '1234xPKR',
          cardStorage: true
        }
      },
      headers: {
        Authorization: '7A4329E5C7464D3997B2CF7D8814379D'
      }
    });
    console.log('Response: ', response.data);
    const { previewUrl } = response.data.payload;
    window.open(previewUrl, '_blank');
  };

  const getOrderInformation = async () => {
    const response = await axios('https://api.abhipay.com.pk/api/v2/getOrderInformation', {
      method: 'POST',
      data: {
        body: {
          sessionId: '38410179-08c8-4efd-ac31-ec49d2d371c3'
        },
        merchant: 'ES1091013'
      },
      headers: {
        Authorization: '7A4329E5C7464D3997B2CF7D8814379D'
      }
    });
    console.log('Response: ', response);
  };

  const getInvoiceInformation = async () => {
    const response = await axios('https://api.abhipay.com.pk/api/v2/invoices', {
      method: 'POST',
      data: {
        merchant: 'ES1091013',
        body: {
          uuid: '123'
        }
      },
      headers: {
        Authorization: '7A4329E5C7464D3997B2CF7D8814379D'
      }
    });
    console.log('Response: ', response);
  };

  return (
    <div className="p-4">
      <div className="mb-2">
        <PrimaryButton type="button" onClick={createOrder}>
          Create Order
        </PrimaryButton>
      </div>
      <div className="mb-2">
        <PrimaryButton type="button" onClick={getOrderInformation}>
          Get Order Information
        </PrimaryButton>
      </div>
      <div className="mb-2">
        <PrimaryButton type="button" onClick={getInvoiceInformation}>
          Get Invoice Information
        </PrimaryButton>
      </div>
    </div>
  );
}

export default AbihPayPage;
